<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Wallet",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Wallet",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Wallet",
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "user", sortable: true, label: "Utilisateur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "dateIns", sortable: true, label: "Inscription" },
        { key: "solde", sortable: true, label: "Solde" },
        { key: "action", label: "Actions" },
      ],
      idUser: 0,
      montantToCredit: 0,
      walletData: [],
      showModalCredit: false,
      showModalRetrait: false,
      showSucess: false,
      showEchec: false,
      walletPositif: 0,
      soldePositif: 0,
      walletNegatif: 0,
      soldeNegatif: 0,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataWallet: [],
      recherche: "",
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.walletData.length;
    },
  },
  async mounted() {
    this.init();
    this.userTotal();
  },

  methods: {
    async init() {
      const infoWallet = await apiRequest(
        "GET",
        "admin/wallet/wallet-info",
        undefined,
        false
      );

      if (infoWallet && infoWallet.data) {
        this.walletPositif = infoWallet.data.walletPositif;
        this.soldePositif = infoWallet.data.soldePositif;
        this.walletNegatif = infoWallet.data.walletNegatif;
        this.soldeNegatif = infoWallet.data.soldeNegatif;
      }

      const listeWallet = await apiRequest(
        "GET",
        "admin/wallet/wallet",
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {
        const WalletListTable = listeWallet.data.map((wallet) => {
          return {
            id: wallet.client.id,
            user:
              wallet.client.personne.lastname +
              " " +
              wallet.client.personne.firstname,
            solde: wallet.solde,
            tel:
              wallet.client.personne.indicatifTel +
              "" +
              wallet.client.personne.tel,
            dateIns: new Date(wallet.client.dateCreation).toLocaleString(
              "fr-FR"
            ),
          };
        });
        this.walletData = WalletListTable;
        this.totalRows = WalletListTable.length

        console.log(this.walletData);
      }
    },
    crediteModal(data) {
      this.idUser = data.id;
      this.showModalCredit = true;
    },
    retraitModal(data) {
      this.idUser = data.id;
      this.showModalRetrait = true;
    },
    async credit() {
      this.showModalCredit = false;

      if (
        this.idUser == 0 ||
        this.montantToCredit == 0 ||
        this.montantToCredit < 0
      ) {
        this.showEchec = true;
      } else {
        const data = await apiRequest(
          "POST",
          "admin/wallet/wallet",
          {
            idUser: this.idUser,
            montant: this.montantToCredit,
          },
          false
        );
        if (data && data.data) {

          this.init();
          
          this.$toast.success("Portefeuille mis à jour avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

        } else {
          this.$toast.error("Erreur. Veuillez réessayer.", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
          this.init();
        }
      }
    },

    async retrait() {
      this.showModalRetrait = false;
      console.log("idUser:", this.idUser);

      if (
        this.idUser == 0 ||
        this.montantToCredit == 0 ||
        this.montantToCredit < 0
      ) {
        this.showEchec = true;
      } else {
        const data = await apiRequest(
          "POST",
          "admin/wallet/retrait",
          {
            idUser: this.idUser,
            montant: this.montantToCredit,
          },
          false
        );
        console.log("data:", data);
        if (data && data.data) {
     


          this.$toast.success("Opération effectuée avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
          this.init();
        } else {
          this.$toast.error("Opération non effectuée", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});



          this.init();
        }
      }
    },

    goToDetail(row) {
      console.log("row:", row);
      router.push(`/user/user_info/${row.id}`);
    },

    async actif(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "admin/wallet/wallet?page=" + page + "&limit=" + limit,
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {
        const WalletListTable = listeWallet.data.map((wallet) => {
          return {
            id: wallet.client.id,
            user:
              wallet.client.personne.lastname +
              " " +
              wallet.client.personne.firstname,
            solde: wallet.solde,
            tel:
              wallet.client.personne.indicatifTel +
              "" +
              wallet.client.personne.tel,
            dateIns: new Date(wallet.client.dateCreation).toLocaleString(
              "fr-FR"
            ),
          };
        });
        this.newDataWallet = WalletListTable;
        this.userChargeLoader = false;
        this.totalRows = WalletListTable.length
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    },

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/wallet/recherche?search="+this.recherche,
undefined,
        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.map((wallet) => {
          return {
            id: wallet.client.id,
            user:
              wallet.client.personne.lastname +
              " " +
              wallet.client.personne.firstname,
            solde: wallet.solde,
            tel:
              wallet.client.personne.indicatifTel +
              "" +
              wallet.client.personne.tel,
            dateIns: new Date(wallet.client.dateCreation).toLocaleString(
              "fr-FR"
            ),
          };
        });
        this.newDataWallet = formattedTable;

        this.userChargeLoader = false;
        this.totalRows = formattedTable.length
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0">Wallets chargés</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ walletPositif }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-info"
                >
                  <template v-slot:header>
                    <h5 class="my-0">Solde chargé</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ soldePositif }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-warning"
                >
                  <template v-slot:header>
                    <h5 class="my-0">Wallets en dette</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ walletNegatif }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-3">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-warning"
                >
                  <template v-slot:header>
                    <h5 class="my-0">Solde de dette</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ soldeNegatif }}</h1>
                  </div>
                </b-card>
              </div>
            </div>
            <!--     <div class="cardBox">
          <div class="carde">
            <div>
              <div class="numbers">{{ walletPositif }}</div>
              <div class="cardName">Wallets chargés</div>
            </div>
            <div class="iconBx">
              <ion-icon name="car-sport-outline"></ion-icon>
            </div>
          </div>
          <div class="carde">
            <div>
              <div class="numbers">{{ soldePositif }}</div>
              <div class="cardName">Solde chargé</div>
            </div>
            <div class="iconBx">
              <ion-icon name="close-circle-outline"></ion-icon>
            </div>
          </div>
          <div class="carde">
            <div>
              <div class="numbers">{{walletNegatif}}</div>
              <div class="cardName"> Wallets en dette</div>
            </div>
            <div class="iconBx">
              <ion-icon name="exit-outline"></ion-icon>
            </div>
          </div>
          <div class="carde">
            <div>
              <div class="numbers">{{walletPositif}}</div>
              <div class="cardName"> Wallets chargés</div>
            </div>
            <div class="iconBx">
              <ion-icon name="exit-outline"></ion-icon>
            </div>
          </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="walletData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div class="row d-flex justify-content-between p-2">
              <div>Total de wallets : {{ totalRows }}</div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == 500"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
           <div class="p-2">

        
                <div
                  class="
                    row
                    d-flex
                    justify-content-between
                    align-items-center
                    mt-4
                  "
                >
                  <div class="p-3 h-10"></div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="recherche"
                        @keyup.enter="userSearch"
                      />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="
                      newDataWallet.length != 0 ? newDataWallet : walletData
                    "
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(action)="row">
                      <a
                        href="javascript:void(0);"
                        class="btn bg-green mr-3 mb-2 w-50"
                        v-b-tooltip.hover
                        title="Créditer le wallet"
                        @click="crediteModal(row.item)"
                      >
                        Créditer
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="btn bg-red mr-3 w-50"
                        v-b-tooltip.hover
                        title="Créditer le wallet"
                        @click="retraitModal(row.item)"
                      >
                        Retrait
                      </a>
                    </template>

                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a
                        :href="'https://wa.me/' + row.value"
                        class="text-success"
                        v-b-tooltip.hover
                        title="Envoyer un message whatsapp"
                        target="_blank"
                      >
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                    <transition mode="out-in">
                      <div
                        class="
                          dataTables_paginate
                          paging_simple_numbers
                          float-right
                        "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button
                              class="myBouton"
                              @click="firstVal()"
                              :disabled="startValue == 1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Prem"
                            >
                              <ion-icon
                                name="play-skip-back-outline"
                              ></ion-icon>
                            </button>
                            <button
                              type="button"
                              class="myBouton"
                              @click="decrement()"
                              :disabled="endValue == 500"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Préc"
                            >
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="increment()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Suiv"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="chevron-forward-outline"
                              ></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="incrementToLast()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Dern"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="play-skip-forward-outline"
                              ></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CREDITER -->
    <b-modal v-model="showModalCredit" title="Créditer le wallet" centered>
      <form>
        <div class="form-group">
          <input
            type="number"
            class="form-control"
            v-model="montantToCredit"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalCredit = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="credit">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <!-- RETIRER -->
    <b-modal v-model="showModalRetrait" title="Retirer du wallet" centered>
      <form>
        <div class="form-group">
          <input
            type="number"
            class="form-control"
            v-model="montantToCredit"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalRetrait = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="retrait">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="Erreur Reéssayez"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <!-- -->
  </Layout>
</template>
<style>
.cardBox {
  position: relative;
  padding: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.cardBox .carde {
  display: flex;
  justify-content: space-between;
  position: relative;
  background: #fff;
  padding: 30px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  cursor: pointer;
}
.cardBox .carde .numbers {
  position: relative;
  font-weight: 500;
  font-size: 2.5rem;
  color: #252b3b;
}

.cardBox .carde .numbers .cardName {
  color: #999;
  font-size: 1.1em;
  margin-top: 5px;
}
.cardBox .carde .iconBx {
  font-size: 3.5em;
  color: #999;
}

body {
  background: #fff;
}

.cardBox .carde:hover {
  background: #252b3b;
}
.cardBox .carde:hover .numbers,
.cardBox .carde:hover .cardName,
.cardBox .carde:hover .iconBx {
  color: #ffffff;
}

.boxNotif {
  box-sizing: border-box;
}

thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
} .paging_simple_numbers ul li{
  color:white!important;
}


.paging_simple_numbers ul li button{
  color:#fff!important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.bg-red{
  background:#dc143c;
  color: #fff;
} 

.nav-tabs .nav-link a{
  color: #fff!important ;
}

.bg-green{
  background  :#3AAA35;
  color: #fff;
}




</style>